/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-select/dist/css/bootstrap-select.css";

:root {
  --primary-color: #5bc500;
  --primary-color-light: #52af00;
  --soft-color: #ebebeb;
  --secondary-text: #b1b1b1;
}

@font-face {
  font-family: "Arial Rounded MT Bold";
  src: local("Arial Rounded MT Bold"), url(./assets/fonts/Arial_Rounded_MT_Bold/arlrdbd.ttf) format("truetype");
}

//Color del texto placeholder para todo el proyecto
::placeholder{
  color: #E3E2EC !important;
}

.toast{
  width: 100%;
  max-width: 100%;
}

html, body { height: 100%; }
body { margin: 0; font-family: "Arial Rounded MT Bold", Arial, Helvetica, sans-serif; }

//** CAMBIAMOS LOS COLORES DE LOS BOTONES PRINCIPALES DE BOOTSTRAP **//
$mynewcolor:#5bc500;
.btn-primary {
  @include button-variant($mynewcolor, darken($mynewcolor, 7.5%), darken($mynewcolor, 10%), lighten($mynewcolor,5%), lighten($mynewcolor, 10%), darken($mynewcolor,30%));
}
  
.btn-outline-primary {
  @include button-outline-variant($mynewcolor, #222222, lighten($mynewcolor,5%), $mynewcolor);
}
//--

.col-xs-1-10,
.col-sm-1-10 {
  position: relative;
  min-height: 1px;
}

.col-xs-1-10 {
  width: 10%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-1-10 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-1-10 {
    width: 10%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-1-10 {
    width: 10%;
    float: left;
  }
}

/** ESTILOS FLEX **/
.flex{
  display: flex;
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-row.center{
  justify-content: center;
}

.flex-row.between{
  justify-content: space-between;
}

.flex-row.around{
  justify-content: space-around;
}

.flex-row.end{
  justify-content: flex-end;
}

.flex-row.start{
  justify-content: flex-start;
}

.flex-row.vcenter{
  align-items: center !important;
}

.flex-row.stretch{
  align-items: stretch;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.flex-column.center{
  justify-content: center;
}

.flex-column.start{
  justify-content: flex-start;
}

.flex-column.vcenter{
  align-items: center;
}

.flex-column.stretch{
  align-items: stretch;
}

.grow1{
  flex-grow: 1;
}

.grow2{
  flex-grow: 2;
}

.grow3{
  flex-grow: 3;
}

.grow4{
  flex-grow: 4;
}

/** FIN ESTILOS FLEX **/

//Agregar asterisco a los elementos requeridos de un formulario
.required:after {
  content:" *";
  color: red;
}
//--

.primary-color{
  color: var(--primary-color) !important;
}

.secondary-text{
  color: var(--secondary-text);
}

.bg-primary-color{
  background-color: var(--primary-color) !important;
}

.btn-verde {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 0;
  
  &:hover {
    color: #fff;
    background-color: var(--primary-color-light);
    border-color: var(--primary-color-light);
  }
}
.btn-verde-outline {
  color: var(--primary-color);
  background-color: #fff;
  border-color: var(--primary-color);
  border-width: 2px;
  border-radius: 0;
  
  &:hover {
    color: var(--primary-color-light);
    background-color: #fff;
    border-color: var(--primary-color-light);
  }
}

.pointer{
  cursor: pointer;
}

.vl {
  border-left: 1px solid var(--soft-color);
  height: 30px;
}


/** badge **/
.badge-parent {
  position:relative;
  display:inline-block;
}
.notify-badge{
  position: absolute;
  right:-10px;
  top:-5px;
  background:#F56A00;
  text-align: center;
  border-radius: 999px;
  color:white;
  padding:3px 4px;
  font-size:10px;
  min-width: 20px;
}

.input-btn{
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #dddddd;
  border-right: none !important;
  font-size: 10px !important;
  font-style: italic;

  -webkit-border-top-left-radius: 100px;
  -webkit-border-bottom-left-radius: 100px;
  -moz-border-radius-topleft: 100px;
  -moz-border-radius-bottomleft: 100px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.page-item.active .page-link{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;;
}

.page-item .page-link{
  color: var(--primary-color);
}

.input-payment{
  margin-bottom: 10px;

  label{
    font-size: 12px;
  }

  
}